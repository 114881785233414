import { getCurrentMember } from '../../services'
import { CognitoGroup } from '../../types/user'

export function useCurrentMemberInGroups(groups?: CognitoGroup[]) {
  const { user } = getCurrentMember()

  if (groups && user && !groups.some((g) => user.groups.includes(g))) {
    return false
  }

  return true
}
