import { DataStore, Predicates, SortDirection } from 'aws-amplify'
import isString from 'lodash/isString'
import { setModel } from '.'
import { Season } from '../models'
import { SeasonModel } from '../types/model'

export async function getSeason(seasonId: string) {
  const season = await DataStore.query(Season, (s) => s.id.eq(seasonId))
  return season[0]
}

export async function listSeason() {
  return await DataStore.query(Season, Predicates.ALL, {
    sort: (s) => s.start(SortDirection.ASCENDING),
  })
}

export function initSeason(): SeasonModel {
  return {
    fields: {
      name: undefined,
      start: undefined,
    },
  }
}

export async function createSeason(
  seasonOrId?: Season | string | null
): Promise<SeasonModel> {
  const season = isString(seasonOrId)
    ? await getSeason(seasonOrId)
    : seasonOrId || null

  const model = initSeason()

  return setModel(season, model)
}

export async function saveSeason(model: SeasonModel) {
  const { fields } = model

  const season = fields.id
    ? Season.copyOf(await getSeason(fields.id), (e) => Object.assign(e, fields))
    : new Season(fields)

  await DataStore.save(season)
}

export async function deleteSeason(model: SeasonModel) {
  const { fields } = model

  if (fields.id) {
    await DataStore.delete(await getSeason(fields.id))
  }
}
