import { DataStore, Predicates, SortDirection } from 'aws-amplify'
import isString from 'lodash/isString'
import { Venue } from '../models'
import { VenueModel } from '../types/model'
import { Option } from '../types/options'

/** Gets a Venue by its identifier. */
export async function getVenue(id: string) {
  const results = await DataStore.query(Venue, (v) => v.id.eq(id))
  return results[0]
}

/** Gets an empty Venue model. */
export function initVenue(): VenueModel {
  return {
    fields: {
      name: undefined,
      room: undefined,
      streetAddress: undefined,
      city: undefined,
      zipCode: undefined,
      notes: undefined,
    },
  }
}

/** Creates a Venue model from an existing Venue. */
export async function createVenue(
  venueOrId?: Venue | string | null
): Promise<VenueModel> {
  const venue = isString(venueOrId)
    ? await getVenue(venueOrId)
    : venueOrId || null

  const model = initVenue()
  const { fields } = model

  if (venue) {
    fields.id = venue.id

    for (const field in fields) {
      ;(fields as any)[field] = (venue as any)[field]
    }
  }

  return model
}

export async function saveVenue(model: VenueModel) {
  const { fields } = model

  const venue = fields.id
    ? Venue.copyOf(await getVenue(fields.id), (e) => Object.assign(e, fields))
    : new Venue(fields)

  await DataStore.save(venue)
}

export async function deleteVenue(model: VenueModel) {
  const { fields } = model

  if (fields.id) {
    await DataStore.delete(await getVenue(fields.id))
  }
}

export async function listVenues() {
  return await DataStore.query(Venue, Predicates.ALL, {
    sort: (s) => s.name(SortDirection.ASCENDING).room(SortDirection.ASCENDING),
  })
}

/** Gets a list of all Venues. */
export async function getVenueModelList() {
  const models: VenueModel[] = []
  const venues = await listVenues()

  for (const venue of venues) {
    models.push(await createVenue(venue))
  }

  return models
}

/** Gets a list of all Venues as options for a dropdown. */
export async function getVenueOptions(): Promise<Option[]> {
  const venues = await listVenues()
  return venues.map(({ id, name, room }) => ({
    value: id || '',
    text: name ? `${name}${room ? ` - ${room}` : ''}` : '',
  }))
}
