import { ShieldBan } from 'lucide-react'
import { useId } from 'react'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { CognitoGroup } from '../../types/user'
import s from './shield.module.css'

type ShieldProps = {
  size?: number
  groups?: CognitoGroup[]
  tooltipPlace?: PlacesType
}

export type PlacesType =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'

export function Shield({
  size = 20,
  groups,
  tooltipPlace = 'left',
}: ShieldProps) {
  const id = useId()

  return groups ? (
    <>
      <ShieldBan
        size={size}
        strokeWidth="2"
        data-tooltip-id={id}
        opacity={0.5}
      />

      <Tooltip
        id={id}
        place={tooltipPlace}
        content={`Only visible to ${getGroupList(groups)}.`}
        className={s.shieldTooltip}
        style={{
          fontSize: 13,
          fontWeight: 600,
          backdropFilter: '4px',
          padding: '6px 8px',
        }}
      />
    </>
  ) : null
}

const getGroupList = (groups?: CognitoGroup[]) => {
  if (!groups) return ''
  try {
    const lf = new Intl.ListFormat('en')
    return lf.format(groups)
  } catch {
    return groups.join(', ')
  }
}
