import { DataStore } from 'aws-amplify'
import { getCurrentMemberId } from '.'
import { debugSave } from '../debug'
import { Commitment, LazyCommitment } from '../models'
import { CommitmentModel } from '../types/model'

/** Gets a commitment record for the member and series. */
export async function getCommitment(
  memberId: string,
  seriesId: string
): Promise<LazyCommitment> {
  const commitment = await DataStore.query(Commitment, (c) =>
    c.and((c) => [c.memberId.eq(memberId), c.seriesId.eq(seriesId)])
  )

  return commitment[0]
}

/** Gets a list of commitments for the member and series ids. */
export async function listCommitment(memberId: string, seriesIds: string[]) {
  return await DataStore.query(Commitment, (c) =>
    c.and((c) => [
      c.memberId.eq(memberId),
      c.or((c) => seriesIds.map((seriesId) => c.seriesId.eq(seriesId))),
    ])
  )
}

/** Gets a list of all commitments for a series. */
export async function listCommitmentsForSeries(seriesId: string) {
  return await DataStore.query(Commitment, (c) => c.seriesId.eq(seriesId))
}

/** Saves a commitment. */
export async function saveCommitment(model: CommitmentModel) {
  const { fields } = model

  fields.updatedByMemberId = getCurrentMemberId()

  const commitment = fields.id
    ? Commitment.copyOf(
        await getCommitment(fields.memberId, fields.seriesId),
        (c) => Object.assign(c, fields)
      )
    : new Commitment(fields)

  debugSave('saveCommitment', { model, commitment })

  await DataStore.save(commitment)
}

/** Initializes a commitment model. */
export function initCommitment(
  memberId?: string,
  seriesId?: string
): CommitmentModel {
  const currentMemberId = getCurrentMemberId()
  return {
    fields: {
      memberId: memberId || '',
      seriesId: seriesId || '',
      allPerformances: false,
      somePerformances: false,
      noPerformances: false,
      performanceEventIds: [],
      notes: '',
      createdByMemberId: currentMemberId,
      updatedByMemberId: currentMemberId,
    },
    rels: {
      series: null,
      performances: [],
    },
    meta: {
      hasPassed: false,
    },
  }
}
