import { Event, EventType } from '../../models'
import {
  REHEARSAL_TYPES,
  createEvent,
  getEvent,
  initEvent,
  listEvent,
} from '../../services/event'
import { EventModel } from '../../types/model'
import { SeasonSeriesParams } from '../../types/params'
import { getFilteredAtomFamily, getModelAtomFamilies } from '../common'

const {
  refreshAtomFamily: eventRefreshAtomFamily,
  listAtomFamily: eventListAtomFamily,
  mapAtomFamily: eventMapAtomFamily,
  countAtomFamily: eventCountAtomFamily,
  optionsAtomFamily: eventOptionsAtomFamily,
  modelAtomFamily: eventModelAtomFamily,
} = getModelAtomFamilies<Event, EventModel, SeasonSeriesParams>(
  'event',
  initEvent,
  createEvent,
  listEvent,
  getEvent
)

const seasonPerformanceListAtomFamily = getFilteredAtomFamily(
  'seasonPerformance',
  eventListAtomFamily,
  () => (e) => e.fields.type === EventType.PERFORMANCE
)

const seasonRehearsalListAtomFamily = getFilteredAtomFamily(
  'seasonRehearsal',
  eventListAtomFamily,
  () => (e) => !!e.fields.type && REHEARSAL_TYPES.includes(e.fields.type)
)

const seasonOtherEventListAtomFamily = getFilteredAtomFamily(
  'seasonOtherEvent',
  eventListAtomFamily,
  () => (e) =>
    e.fields.type !== EventType.PERFORMANCE &&
    !REHEARSAL_TYPES.includes(e.fields.type as EventType)
)

const seriesPerformanceListAtomFamily = getFilteredAtomFamily<
  EventModel,
  SeasonSeriesParams
>(
  'seriesPerformance',
  seasonPerformanceListAtomFamily,
  ({ seriesId }) =>
    (e) =>
      seriesId !== undefined && e.fields.seriesIds?.includes(seriesId) === true,
  ({ seasonId }) => ({ seasonId })
)

const seriesRehearsalListAtomFamily = getFilteredAtomFamily<
  EventModel,
  SeasonSeriesParams
>(
  'seriesRehearsal',
  seasonRehearsalListAtomFamily,
  ({ seriesId }) =>
    (e) =>
      seriesId !== undefined && e.fields.seriesIds?.includes(seriesId) === true,
  ({ seasonId }) => ({ seasonId })
)

const seriesOtherEventListAtomFamily = getFilteredAtomFamily<
  EventModel,
  SeasonSeriesParams
>(
  'seriesOtherEvent',
  seasonOtherEventListAtomFamily,
  ({ seriesId }) =>
    (e) =>
      seriesId !== undefined && e.fields.seriesIds?.includes(seriesId) === true,
  ({ seasonId }) => ({ seasonId })
)

export {
  eventCountAtomFamily,
  eventListAtomFamily,
  eventMapAtomFamily,
  eventModelAtomFamily,
  eventOptionsAtomFamily,
  eventRefreshAtomFamily,
  seasonOtherEventListAtomFamily,
  seasonPerformanceListAtomFamily,
  seasonRehearsalListAtomFamily,
  seriesOtherEventListAtomFamily,
  seriesPerformanceListAtomFamily,
  seriesRehearsalListAtomFamily,
}
