import { rootPages } from '../router'
import { AppRoute, NavTree } from '../types/route'

export function getPathEnd(path: string = window.location.pathname) {
  const parts = path.split('/')
  return parts.length ? parts[parts.length - 1] : path
}

/**
 * Gets a representation of the top-level page > tabs hierarchy for use in the
 * Nav & Tabs components.
 *
 * @returns
 */
export function getNavTree(): NavTree {
  return rootPages.map(({ path, title, children, groups }) => {
    const tabs = (children || []).filter((c) => !!c.path) as AppRoute[]

    return {
      title,
      path: `/${path}`,
      end: getPathEnd(path),
      tabs: tabs.map(({ path: tabPath, title, groups }) => {
        tabPath = `/${path}/${tabPath}`

        return {
          title,
          path: tabPath,
          end: getPathEnd(tabPath),
          groups,
          active: false,
        }
      }),
      groups,
      active: false,
    }
  })
}
