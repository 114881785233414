import {
  WithAuthenticatorProps,
  withAuthenticator,
} from '@aws-amplify/ui-react'
import cx from 'classnames'
import { useAtom, useAtomValue } from 'jotai'
import { forwardRef, startTransition, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { BreakpointName } from '../../concerns/breakpoints'
import { debugLog } from '../../debug'
import { useBreakpoint } from '../../hooks/interface/useBreakpoint'
import { useCurrentMemberInGroups } from '../../hooks/model/useCurrentMemberInGroups'
import { useNavRoutes } from '../../hooks/router/useNavRoutes'
import { showOverlayAtom } from '../../state/interface'
import { todayAtom, todayDisplayAtom } from '../../state/today/today'
import { getEnv } from '../../util/env'
import { AdminDirectorLeaderOnly } from '../../util/groups'
import { ActionButton } from './action'
import { Breakpoint } from './breakpoint'
import { Guard } from './guard'
import { Link, NavLink } from './link'
import { Logo } from './logo'
import s from './nav.module.css'
import { Shield } from './shield'

const { MediumScreen, SmallScreen } = BreakpointName

type NavProps = {} & WithAuthenticatorProps

export const Nav = withAuthenticator(({ signOut }: NavProps) => {
  const navTree = useNavRoutes('nav')
  const inGroups = useCurrentMemberInGroups(AdminDirectorLeaderOnly)

  const [today, setToday] = useAtom(todayAtom)
  const todayDisplay = useAtomValue(todayDisplayAtom)

  const TodayDisplay = forwardRef<HTMLDivElement, { onClick?: any }>(
    ({ onClick }, ref) => (
      <div
        className={cx(s.date, { [s.clickable]: inGroups })}
        onClick={onClick}
        ref={ref}
      >
        {todayDisplay}
      </div>
    )
  )

  const { breakpointName, navAsSidebar, navAsHeader, isSmallScreen } =
    useBreakpoint()

  const [showOverlay, setShowOverlay] = useAtom(showOverlayAtom)

  useEffect(() => {
    if (!isSmallScreen) {
      setShowOverlay(false)
    }
  }, [isSmallScreen, setShowOverlay])

  debugLog('render', () => ['Nav', { navTree }])

  return (
    <div
      className={cx(
        s.container,
        {
          [s.sidebar]: navAsSidebar,
          [s.header]: navAsHeader,
          [s.overlay]: showOverlay,
        },
        s[breakpointName]
      )}
    >
      <div className={s.banner}>
        <Link className={s.logo} to="/home">
          <Logo size={navAsSidebar ? 'large' : 'small'} />
        </Link>

        {navAsSidebar && inGroups ? (
          <DatePicker
            selected={today}
            onChange={(date) => {
              startTransition(() => setToday(date as Date))
            }}
            customInput={<TodayDisplay />}
            todayButton="Today"
          />
        ) : navAsSidebar ? (
          <TodayDisplay />
        ) : null}

        <div className={s.navItems}>
          <Breakpoint gte={MediumScreen}>{renderNavItems()}</Breakpoint>
        </div>

        {navAsSidebar && renderFoot()}

        {navAsHeader && (
          <>
            <Breakpoint gte={MediumScreen}>
              <div className={s.menu}>
                <ActionButton text="Sign Out" onClick={signOut} />
              </div>
            </Breakpoint>

            <Breakpoint eq={SmallScreen}>
              <ActionButton
                className={s.menu}
                text="Menu"
                onClick={onToggleMobileOverlay}
              />
              {/* <div className={s.menu} onClick={onToggleMobileOverlay}>
                <Menu />
              </div> */}
            </Breakpoint>
          </>
        )}
      </div>

      {showOverlay && isSmallScreen && (
        <div className={s.overlayContent}>{renderNavItems()}</div>
      )}
    </div>
  )

  function renderNavItems() {
    return navTree?.map(({ path, title, tabs, groups, active }) => (
      <Guard groups={groups} key={path}>
        <NavLink
          className={s.navItem}
          to={path}
          onClick={() => setShowOverlay(false)}
        >
          {title}
          <Shield groups={groups} tooltipPlace="bottom-end" />
        </NavLink>

        {showOverlay && isSmallScreen && (
          <div className={s.navTabItems}>
            {tabs?.map(({ path, title, groups }) => (
              <Guard groups={groups} key={path}>
                <NavLink
                  className={s.navTabItem}
                  to={path}
                  onClick={() => setShowOverlay(false)}
                >
                  {title}
                </NavLink>
              </Guard>
            ))}
          </div>
        )}
      </Guard>
    ))
  }

  function renderFoot() {
    return (
      <div className={s.foot}>
        <ActionButton text="Sign Out" onClick={signOut} />
        <div className={s.env}>{getEnv()}</div>
      </div>
    )
  }

  function onToggleMobileOverlay() {
    setShowOverlay((val) => !val)
  }
})
