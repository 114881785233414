import { Series } from '../../models'
import {
  createSeries,
  getSeries,
  initSeries,
  listSeries,
} from '../../services/series'
import { SeriesModel } from '../../types/model'
import { SeasonParam } from '../../types/params'
import { getModelAtomFamilies } from '../common'

const {
  refreshAtomFamily: seriesRefreshAtomFamily,
  listAtomFamily: seriesListAtomFamily,
  mapAtomFamily: seriesMapAtomFamily,
  countAtomFamily: seriesCountAtomFamily,
  optionsAtomFamily: seriesOptionsAtomFamily,
  modelAtomFamily: seriesAtomFamily,
} = getModelAtomFamilies<Series, SeriesModel, SeasonParam>(
  'series',
  initSeries,
  createSeries,
  listSeries,
  getSeries
)

export {
  seriesAtomFamily,
  seriesCountAtomFamily,
  seriesListAtomFamily,
  seriesMapAtomFamily,
  seriesOptionsAtomFamily,
  seriesRefreshAtomFamily,
}
