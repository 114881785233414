import { atomFamily } from 'jotai/utils'
import isEqual from 'lodash/isEqual'
import { debugLog } from '../../debug'
import { getRosterForSeason } from '../../services/roster'
import { SeasonParam } from '../../types/params'
import { getAtomWithRefresh, getLabel } from '../common'
import { memberMapAtom } from './member'

export const rosterAtomFamily = atomFamily(
  (param: SeasonParam) =>
    getAtomWithRefresh(
      async (get) => {
        const roster = await getRosterForSeason(param.seasonId)
        const memberMap = await get(memberMapAtom)

        debugLog('roster', () => {
          const notFoundMemberIds = roster?.meta.allMemberIds.filter(
            (id) => id in memberMap === false
          )
          return ['memberIds not found', notFoundMemberIds]
        })

        return roster
      },
      getLabel('roster', '', param)
    ),
  isEqual
)
