import { Config } from '../types/config'
import production from './production.json'
import staging from './staging.json'

const environments: {
  production: Config
  staging: Config
  [env: string]: Config
} = {
  production,
  staging,
}

export default environments
