export interface CognitoUser {
  Attributes: UserAttribute[]
  Enabled: boolean
  UserCreateDate: string
  UserLastModifiedDate: string
  UserStatus: string
  Username: string
}

export interface UserAttribute {
  Name: string
  Value: string
}

export interface CognitoGroupUsers {
  Users: CognitoUser[]
}

export interface Group {
  name: string
  subs: string[]
}

export enum CognitoGroup {
  Admin = 'AdminGroup',
  Director = 'DirectorGroup',
  Leader = 'LeaderGroup',
}

export interface User {
  sub: string
  email: string
  emailVerified: boolean
  enabled?: boolean
  status?: string
  groups: string[]
  created?: string
  modified?: string
  admin?: boolean
}
