import { DataStore } from 'aws-amplify'
import { getCurrentMemberId, setModel } from '.'
import { debugSave } from '../debug'
import { Role, Roster } from '../models'
import { MemberModel, RosterModel } from '../types/model'

export async function getRoster(id: string) {
  const roster = await DataStore.query(Roster, (r) => r.id.eq(id))
  return roster[0]
}

export async function getRosterForSeason(seasonId?: string) {
  if (!seasonId) return undefined
  const result = await DataStore.query(Roster, (r) => r.seasonId.eq(seasonId))
  const roster = result.length ? result[0] : undefined
  return roster ? createRoster(roster) : initRoster(seasonId)
}

export async function saveRoster(model: RosterModel) {
  const { fields } = model

  fields.updatedByMemberId = getCurrentMemberId()

  const roster = fields.id
    ? Roster.copyOf(await getRoster(fields.id), (r) => Object.assign(r, fields))
    : new Roster(fields)

  debugSave('saveRoster', { model, roster })

  await DataStore.save(roster)
}

export function initRoster(seasonId: string): RosterModel {
  return {
    fields: initRosterFields(seasonId),
    meta: {
      allMemberIds: [],
      allCount: 0,
      sopranoCount: 0,
      altoCount: 0,
      tenorCount: 0,
      bassCount: 0,
      offRosterAllCount: undefined,
      offRosterSopranoCount: undefined,
      offRosterAltoCount: undefined,
      offRosterTenorCount: undefined,
      offRosterBassCount: undefined,
    },
  }
}

export function initRosterFields(seasonId: string) {
  const currentMemberId = getCurrentMemberId()
  return {
    seasonId,
    sopranoMemberIds: [],
    altoMemberIds: [],
    tenorMemberIds: [],
    bassMemberIds: [],
    nonSingerMemberIds: [],
    createdByMemberId: currentMemberId,
    updatedByMemberId: currentMemberId,
  }
}

export async function createRoster(rosterOrSeasonId: Roster | string) {
  const roster =
    rosterOrSeasonId instanceof Roster
      ? (rosterOrSeasonId as Roster)
      : await getRoster(rosterOrSeasonId as string)

  const model = initRoster(roster.seasonId)

  return setModel(roster, model, async (model) => {
    const {
      sopranoMemberIds: s,
      altoMemberIds: a,
      tenorMemberIds: t,
      bassMemberIds: b,
      nonSingerMemberIds: n,
    } = model.fields

    model.meta.allMemberIds = [...s, ...a, ...t, ...b, ...n]
    model.meta.allCount = model.meta.allMemberIds.length
    model.meta.sopranoCount = s.length
    model.meta.altoCount = a.length
    model.meta.tenorCount = t.length
    model.meta.bassCount = b.length
  })
}

const ROLE_MEMBERIDS_PROP = {
  [Role.SOPRANO]: 'sopranoMemberIds',
  [Role.ALTO]: 'altoMemberIds',
  [Role.TENOR]: 'tenorMemberIds',
  [Role.BASS]: 'bassMemberIds',
  [Role.DIRECTOR]: 'nonSingerMemberIds',
  [Role.PIANIST]: 'nonSingerMemberIds',
  [Role.LIASON]: 'nonSingerMemberIds',
}

export function addMember(member: MemberModel) {
  if (!member.fields.role) {
    throw new Error('Member role expected but not found')
  }

  const memberIdsProp = ROLE_MEMBERIDS_PROP[member.fields.role]

  return (roster: RosterModel) => {
    const memberIds = roster.fields[memberIdsProp]

    return {
      ...roster,
      fields: {
        ...roster.fields,
        [memberIdsProp]: [...memberIds, member.fields.id],
      },
    }
  }
}

export function removeMember(member: MemberModel) {
  if (!member.fields.role) {
    throw new Error('Member role expected but not found')
  }

  const memberIdsProp = ROLE_MEMBERIDS_PROP[member.fields.role]

  return (roster: RosterModel) => {
    const memberIds = roster.fields[memberIdsProp] as string[]

    return {
      ...roster,
      fields: {
        ...roster.fields,
        [memberIdsProp]: memberIds.filter((id) => id !== member.fields.id),
      },
    }
  }
}
