import { Auth, DataStore, Predicates, SortDirection } from 'aws-amplify'
import isString from 'lodash/isString'
import { getCurrentMemberId, setModel } from '.'
import { Member } from '../models'
import { MemberFields, MemberModel } from '../types/model'

export async function getCurrentMember() {
  const user = await Auth.currentAuthenticatedUser()
  return await createMemberFromAuthUser(user)
}

export async function getMember(memberId: string) {
  const member = await DataStore.query(Member, (m) => m.id.eq(memberId))
  return member[0]
}

export async function getMembers(memberIds: string[]) {
  return memberIds.length > 0
    ? await DataStore.query(Member, (m) =>
        m.or((m) => memberIds.map((memberId) => m.id.eq(memberId)))
      )
    : []
}

export async function getMemberBySub(sub?: string) {
  const member = await DataStore.query(Member, (m) => m.sub.eq(sub))

  if (member.length === 0) {
    throw new Error(`Member was not found for sub: ${sub}`)
  }

  return member[0]
}

export async function listMembers() {
  return await DataStore.query(Member, Predicates.ALL, {
    sort: (s) =>
      s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING),
  })
}

export async function listMemberModels() {
  const models: MemberModel[] = []
  const list = await listMembers()

  for (const member of list) {
    models.push(await createMember(member))
  }

  return models
}

export async function saveMemberPhoto(memberId: string, photoKey: string) {
  const member = await DataStore.query(Member, (m) => m.id.eq(memberId))

  await DataStore.save(
    Member.copyOf(member[0], (m) => {
      m.photo = photoKey
    })
  )
}

export function initMember(includeAuditFields = true): MemberModel {
  return {
    fields: initMemberFields(includeAuditFields),
  }
}

export function initMemberFields(includeAuditFields = true): MemberFields {
  const fields: MemberFields = {
    firstName: undefined,
    lastName: undefined,
    programName: undefined,
    role: undefined,
    leader: false,
    loginEmail: undefined,
    email: undefined,
    phone: undefined,
    streetAddress: undefined,
    city: undefined,
    zipCode: undefined,
    alternateEmails: [],
    alternatePhones: [],
    notes: undefined,
    photo: undefined,
    biography: undefined,
    sub: undefined,
    createdByMemberId: '',
    updatedByMemberId: '',
  }

  if (includeAuditFields) {
    const currentMemberId = getCurrentMemberId()
    fields.createdByMemberId = currentMemberId
    fields.updatedByMemberId = currentMemberId
  }

  return fields
}

export async function createMemberFromAuthUser(user: any) {
  const member = await getMemberBySub(user.username)
  const model = initMember(false)

  return setModel(member, model, async (model) => {
    const { sub, email, email_verified: emailVerified } = user.attributes
    const { payload } = user.signInUserSession.accessToken
    const groups = 'cognito:groups' in payload ? payload['cognito:groups'] : []

    model.user = {
      sub,
      email,
      emailVerified,
      groups,
      admin: groups.includes('AdminGroup'),
    }
  })
}

export async function createMember(memberOrId: Member | string) {
  const member = isString(memberOrId) ? await getMember(memberOrId) : memberOrId
  const model = initMember()

  return setModel(member, model)
}

export async function saveMember(model: MemberModel) {
  model = {
    ...model,
    fields: {
      ...model.fields,
      updatedByMemberId: getCurrentMemberId(),
    },
  }

  const { fields } = model

  const member = fields.id
    ? Member.copyOf(await getMember(fields.id), (s) => Object.assign(s, fields))
    : new Member(fields)

  await DataStore.save(member)
}

export async function deleteMember(model: MemberModel) {
  const { fields } = model

  if (fields.id) {
    await DataStore.delete(await getMember(fields.id))
  }
}
