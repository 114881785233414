import { Sizes } from '@aws-amplify/ui-react'
import cx from 'classnames'
import s from './logo.module.css'

export function Logo({ size = 'large' }: { size?: Sizes }) {
  return (
    <div className={cx(s.logo, { [s[size as string]]: !!size })}>
      {size === 'large' && (
        <img
          src="/assets/ssc.png"
          alt="Seattle Symphony Chorale"
          width="160px"
        />
      )}

      {size === 'small' && (
        <>
          <div className={s.seattle}>Seattle</div>
          <div className={s.symphony}>Symphony</div>
          <div className={s.chorale}>Chorale</div>
        </>
      )}
    </div>
  )
}
