import { atom } from 'jotai'
import moment, { Moment } from 'moment'
import { SeasonModel } from '../../types/model'
import { seasonListAtom } from '../model/season'
import { todayAtom } from './today'

/** Current season based on the current today date. */
export const currentSeasonAtom = atom(async (get) => {
  const seasonList = await get(seasonListAtom)
  const today = moment(get(todayAtom))

  let start: Moment | null = null
  let currentSeason: SeasonModel = seasonList[0]

  for (const season of seasonList) {
    start = moment(season.fields.start)

    if (today.isSameOrAfter(start)) {
      currentSeason = season
    }
  }

  return currentSeason
})

currentSeasonAtom.debugLabel = 'currentSeason'
