/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://jszgfqhyy9.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "DownloadQueries",
            "endpoint": "https://5r07cb7q41.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://f7fv3scyyred7n3yss66oono6q.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-wjccfymzofantodeoyjao7xynq",
    "aws_cognito_identity_pool_id": "us-west-2:264671ea-866f-43d9-b1ed-7dc2147d3f2b",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_e5kZfhJzF",
    "aws_user_pools_web_client_id": "6jmjuvbpcli8l6kg40e7e6kuug",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ssc-portal-storage200535-production",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
