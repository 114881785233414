import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { Navigate } from './components/common/navigate'
import { Root } from './components/root'
import { debugLog } from './debug'
import { Role } from './models'
import { AppRoute } from './types/route'
import { AdminDirectorLeaderOnly, AdminOnly } from './util/groups'

const AccountEditForm = lazy(() => import('./components/admin/accounts/edit'))
const AdminPage = lazy(() => import('./components/admin/page'))
const CommitmentsEditModal = lazy(() => import('./components/commitments/edit'))
const CommitmentsPage = lazy(() => import('./components/commitments/page'))
const CommitmentsSectionTab = lazy(
  () => import('./components/commitments/sections/tab')
)
const CommitmentsSummaryTab = lazy(
  () => import('./components/commitments/summary/tab')
)
const HomePage = lazy(() => import('./components/home/page'))
const MemberDeleteModal = lazy(
  () => import('./components/admin/members/delete')
)
const MemberEditModal = lazy(() => import('./components/admin/members/edit'))
const MembersTab = lazy(() => import('./components/admin/members/tab'))
const MyAbsencesTab = lazy(() => import('./components/home/absences/tab'))
const MyCommitmentsTab = lazy(() => import('./components/home/commitments/tab'))
const MyProfileTab = lazy(() => import('./components/home/profile/tab'))
const ParkingDeleteModal = lazy(
  () => import('./components/admin/parking/delete')
)
const ParkingEditModal = lazy(() => import('./components/admin/parking/edit'))
const ParkingTab = lazy(() => import('./components/admin/parking/tab'))
const PerformanceDeleteModal = lazy(
  () => import('./components/seasons/performances/delete')
)
const PerformanceEditModal = lazy(
  () => import('./components/seasons/performances/edit')
)
const PerformancesTab = lazy(
  () => import('./components/seasons/performances/tab')
)
const PreferencesTab = lazy(() => import('./components/home/preferences/tab'))
const RehearsalDeleteModal = lazy(
  () => import('./components/seasons/rehearsals/delete')
)
const RehearsalEditModal = lazy(
  () => import('./components/seasons/rehearsals/edit')
)
const RehearsalsTab = lazy(() => import('./components/seasons/rehearsals/tab'))
const RosterIndexRoute = lazy(() => import('./components/roster'))
const RosterPage = lazy(() => import('./components/roster/page'))
const RosterTab = lazy(() => import('./components/roster/tab'))
const SeasonDeleteModal = lazy(
  () => import('./components/admin/seasons/delete')
)
const SeasonEditModal = lazy(() => import('./components/admin/seasons/edit'))
const SeasonPage = lazy(() => import('./components/seasons/page'))
const SeasonsTab = lazy(() => import('./components/admin/seasons/tab'))
const SeriesDeleteModal = lazy(
  () => import('./components/seasons/series/delete')
)
const SeriesDetail = lazy(() => import('./components/seasons/series/detail'))
const SeriesEditModal = lazy(() => import('./components/seasons/series/edit'))
const SeriesPage = lazy(() => import('./components/seasons/series/page'))
const SeriesTab = lazy(() => import('./components/seasons/series/tab'))
const VenueDeleteModal = lazy(() => import('./components/admin/venues/delete'))
const VenueEditModal = lazy(() => import('./components/admin/venues/edit'))
const VenuesTab = lazy(() => import('./components/admin/venues/tab'))

export const homeIndexPath = 'commitments'

const performanceModals = [
    {
      path: 'add',
      element: <PerformanceEditModal mode="Add" />,
    },
    {
      path: ':performanceId/edit',
      element: <PerformanceEditModal mode="Edit" />,
    },
    {
      path: ':performanceId/delete',
      element: <PerformanceDeleteModal />,
    },
  ],
  rehearsalModals = [
    {
      path: 'add',
      element: <RehearsalEditModal mode="Add" />,
    },
    {
      path: ':rehearsalId/edit',
      element: <RehearsalEditModal mode="Edit" />,
    },
    {
      path: ':rehearsalId/delete',
      element: <RehearsalDeleteModal />,
    },
  ],
  seriesModals = [
    {
      path: 'edit',
      element: <SeriesEditModal mode="Edit" />,
    },
    { path: 'delete', element: <SeriesDeleteModal /> },
    { path: 'performances', children: performanceModals },
    { path: 'rehearsals', children: rehearsalModals },
  ],
  memberModals = [
    {
      path: 'add',
      element: <MemberEditModal mode="Add" isAdmin={true} />,
    },
    {
      path: ':memberId/edit',
      element: <MemberEditModal mode="Edit" isAdmin={true} />,
    },
    {
      path: ':memberId/delete',
      element: <MemberDeleteModal />,
    },
  ],
  rosterModals = [
    ...memberModals,
    {
      path: ':memberId/commitments',
      element: <CommitmentsEditModal />,
    },
  ],
  commitmentsModals = [
    {
      path: ':memberId/commitments',
      element: <CommitmentsEditModal />,
    },
  ],
  adminMemberModals = [
    ...memberModals,
    {
      path: ':memberId/account',
      element: <AccountEditForm />,
    },
  ],
  adminSeasonModals = [
    { path: 'add', element: <SeasonEditModal mode="Add" /> },
    {
      path: ':seasonId/edit',
      element: <SeasonEditModal mode="Edit" />,
    },
    {
      path: ':seasonId/delete',
      element: <SeasonDeleteModal />,
    },
  ],
  adminVenueModals = [
    { path: 'add', element: <VenueEditModal mode="Add" /> },
    {
      path: ':venueId/edit',
      element: <VenueEditModal mode="Edit" />,
    },
    {
      path: ':venueId/delete',
      element: <VenueDeleteModal />,
    },
  ],
  adminParkingModals = [
    { path: 'add', element: <ParkingEditModal mode="Add" /> },
    {
      path: ':parkingId/edit',
      element: <ParkingEditModal mode="Edit" />,
    },
    {
      path: ':parkingId/delete',
      element: <ParkingDeleteModal />,
    },
  ]

const homeTabs: AppRoute[] = [
    // {
    //   path: 'posts',
    //   title: 'News & Updates',
    //   element: <PostsTab />,
    // },
    {
      path: 'commitments',
      title: 'My Commitments',
      element: <MyCommitmentsTab />,
    },
    {
      path: 'absences',
      title: 'My Absences',
      element: <MyAbsencesTab />,
    },
    {
      path: 'profile',
      title: 'My Profile',
      element: <MyProfileTab />,
    },
    {
      path: 'preferences',
      title: 'Preferences',
      element: <PreferencesTab />,
    },
  ],
  seasonTabs = [
    {
      path: 'series',
      title: 'Series',
      element: <SeriesTab />,
      children: [{ path: 'add', element: <SeriesEditModal mode="Add" /> }],
    },
    {
      path: 'performances',
      title: 'Performances',
      element: <PerformancesTab />,
      children: performanceModals,
    },
    {
      path: 'rehearsals',
      title: 'Rehearsals',
      element: <RehearsalsTab />,
      children: rehearsalModals,
    },
    // {
    //   path: 'events',
    //   title: 'Events',
    //   element: <EventsTab />,
    // },
  ],
  rosterTabs = [
    {
      path: 'sopranos',
      title: 'Sopranos',
      element: <RosterTab role={Role.SOPRANO} />,
      children: rosterModals,
    },
    {
      path: 'altos',
      title: 'Altos',
      element: <RosterTab role={Role.ALTO} />,
      children: rosterModals,
    },
    {
      path: 'tenors',
      title: 'Tenors',
      element: <RosterTab role={Role.TENOR} />,
      children: rosterModals,
    },
    {
      path: 'basses',
      title: 'Basses',
      element: <RosterTab role={Role.BASS} />,
      children: rosterModals,
    },
    {
      path: 'all',
      title: 'All',
      element: <RosterTab />,
      children: rosterModals,
    },
  ],
  commitmentsTabs = [
    {
      path: 'summary',
      title: 'Summary',
      element: <CommitmentsSummaryTab />,
    },
    {
      path: 'sopranos',
      title: 'Sopranos',
      element: <CommitmentsSectionTab role={Role.SOPRANO} />,
      children: commitmentsModals,
    },
    {
      path: 'altos',
      title: 'Altos',
      element: <CommitmentsSectionTab role={Role.ALTO} />,
      children: commitmentsModals,
    },
    {
      path: 'tenors',
      title: 'Tenors',
      element: <CommitmentsSectionTab role={Role.TENOR} />,
      children: commitmentsModals,
    },
    {
      path: 'basses',
      title: 'Basses',
      element: <CommitmentsSectionTab role={Role.BASS} />,
      children: commitmentsModals,
    },
  ],
  adminTabs = [
    {
      path: 'members',
      title: 'Members',
      element: <MembersTab />,
      children: adminMemberModals,
    },
    {
      path: 'seasons',
      title: 'Seasons',
      element: <SeasonsTab />,
      children: adminSeasonModals,
    },
    {
      path: 'venues',
      title: 'Venues',
      element: <VenuesTab />,
      children: adminVenueModals,
    },
    {
      path: 'parking',
      title: 'Parking',
      element: <ParkingTab />,
      children: adminParkingModals,
    },
  ]

export const rootPages: AppRoute[] = [
  {
    path: 'home',
    title: 'Home',
    element: <HomePage />,
    children: [
      { index: true, element: <Navigate to={homeIndexPath} /> },
      ...homeTabs,
    ],
  },
  {
    path: 'seasons/:seasonId',
    title: 'Season',
    element: <SeasonPage />,
    children: [
      { index: true, element: <Navigate to="series" /> },
      ...seasonTabs,
    ],
  },
  {
    path: 'roster/:seasonId',
    title: 'Roster',
    element: <RosterPage />,
    children: [{ index: true, element: <RosterIndexRoute /> }, ...rosterTabs],
  },
  {
    path: 'commitments/:seasonId',
    title: 'Commitments',
    element: <CommitmentsPage />,
    children: [
      { index: true, element: <Navigate to="summary" /> },
      ...commitmentsTabs,
    ],
    groups: AdminDirectorLeaderOnly,
  },
  {
    path: 'admin',
    title: 'Admin',
    element: <AdminPage />,
    children: [
      { index: true, element: <Navigate to="members" /> },
      ...adminTabs,
    ],
    groups: AdminOnly,
  },
]

export const routes = [
  {
    path: '/',
    element: <Root />,
    children: [
      { index: true, element: <Navigate to="home" /> },
      ...rootPages,
      {
        path: 'seasons/:seasonId/series',
        element: <SeriesPage />,
        children: [
          {
            path: ':seriesId',
            element: <SeriesDetail />,
            children: seriesModals,
          },
        ],
      },
    ],
  },
]

export const router = createBrowserRouter(routes)

debugLog('router', () => [{ routes, router }])
