import MediaQuery from 'react-responsive'
import { BreakpointName, getBreakpointQuery } from '../../concerns/breakpoints'
import { CommonProps } from '../../types/props'

type BreakpointProps = CommonProps<{
  eq?: BreakpointName
  gte?: BreakpointName
  lte?: BreakpointName
}>

/** Prevents render of its children when the current breakpoint does not match. */
export function Breakpoint({ eq, gte, lte, children }: BreakpointProps) {
  const query = eq
    ? getBreakpointQuery('eq', eq)
    : gte
    ? getBreakpointQuery('gte', gte)
    : lte
    ? getBreakpointQuery('lte', lte)
    : undefined

  return query ? <MediaQuery {...query}>{children}</MediaQuery> : null
}
