import { Parking } from '../../models'
import {
  createParking,
  getParking,
  initParking,
  listParking,
} from '../../services/parking'
import { ParkingModel } from '../../types/model'
import { getModelAtoms } from '../common'

const {
  refreshAtom: parkingRefreshAtom,
  listAtom: parkingListAtom,
  mapAtom: parkingMapAtom,
  countAtom: parkingCountAtom,
  optionsAtom: parkingOptionsAtom,
} = getModelAtoms<Parking, ParkingModel>(
  'parking',
  initParking,
  createParking,
  listParking,
  getParking
)

export {
  parkingCountAtom,
  parkingListAtom,
  parkingMapAtom,
  parkingOptionsAtom,
  parkingRefreshAtom,
}
