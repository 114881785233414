import { useCurrentMemberInGroups } from '../../hooks/model/useCurrentMemberInGroups'
import { CommonProps } from '../../types/props'
import { CognitoGroup } from '../../types/user'

type GuardProps = CommonProps<{
  groups?: CognitoGroup[]
}>

/**
 * Prevents render of its children when the current member is not in any of the
 * groups provided.
 */
export function Guard({ groups, children }: GuardProps) {
  const inGroups = useCurrentMemberInGroups(groups)
  return <>{inGroups ? children : null}</>
}
