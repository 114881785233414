export enum BreakpointName {
  BigScreen = 'big',
  LargeScreen = 'large',
  MediumScreen = 'medium',
  SmallScreen = 'small',
}

const { BigScreen, LargeScreen, MediumScreen, SmallScreen } = BreakpointName

const BreakpointOrder = [BigScreen, LargeScreen, MediumScreen, SmallScreen]
const BreakpointBoundaries = [1600, 1025, 768, 0]

export function getBreakpointQuery(
  operator: 'eq' | 'lte' | 'gte',
  name: BreakpointName
) {
  const index = BreakpointOrder.indexOf(name)

  return operator === 'eq'
    ? {
        minWidth: BreakpointBoundaries[index],
        maxWidth: index > 0 ? BreakpointBoundaries[index - 1] - 1 : undefined,
      }
    : operator === 'lte'
    ? {
        minWidth: undefined,
        maxWidth: index > 0 ? BreakpointBoundaries[index - 1] - 1 : undefined,
      }
    : operator === 'gte'
    ? {
        minWidth: BreakpointBoundaries[index],
        maxWidth: undefined,
      }
    : {
        minWidth: undefined,
        maxWidth: undefined,
      }
}
