import cx from 'classnames'
import {
  ArrowDownToLine,
  LucideIcon,
  PlusIcon,
  RotateCwIcon,
  Trash2Icon,
} from 'lucide-react'
import { MouseEventHandler } from 'react'
import { CommonProps } from '../../types/props'
import { CognitoGroup } from '../../types/user'
import s from './action.module.css'
import { Guard } from './guard'
import { Link } from './link'

export type ActionButtonType =
  | 'default'
  | 'danger'
  | 'success'
  | 'warning'
  | 'primary'
  | 'primary-darker-1'
  | 'secondary'

export type ActionButtonProps = CommonProps<{
  id?: string
  type?: ActionButtonType
  icon?: LucideIcon
  text?: string
  to?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  selected?: boolean
  groups?: CognitoGroup[]
}>

export function ActionButton({
  className,
  type = 'default',
  icon: Icon,
  text,
  to,
  onClick,
  disabled = false,
  selected = false,
  groups: onlyForGroups,
}: ActionButtonProps) {
  return (
    <Guard groups={onlyForGroups}>
      {to ? (
        <Link className={cx(s.button, s[type])} to={to}>
          {renderIcon()}
          {text}
        </Link>
      ) : (
        <button
          className={cx(s.button, s[type], className, {
            [s.disabled]: disabled,
            [s.selected]: selected,
          })}
          onClick={onClick}
          disabled={disabled}
        >
          {renderIcon()}
          {text}
        </button>
      )}
    </Guard>
  )

  function renderIcon() {
    return Icon ? <Icon size="14px" /> : null
  }
}

export function RefreshButton({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <ActionButton
      icon={RotateCwIcon}
      text="Refresh"
      type="success"
      onClick={onClick}
    />
  )
}

export function AddButton({
  text,
  to,
  groups,
}: {
  text: string
  to: string
  groups?: CognitoGroup[]
}) {
  return <ActionButton icon={PlusIcon} text={text} to={to} groups={groups} />
}

export function EditButton({
  to,
  groups,
}: {
  to: string
  groups?: CognitoGroup[]
}) {
  return <ActionButton text="Edit" to={to} groups={groups} />
}

export function DeleteButton({
  to,
  groups,
}: {
  to: string
  groups?: CognitoGroup[]
}) {
  return (
    <ActionButton type="danger" icon={Trash2Icon} to={to} groups={groups} />
  )
}

export function DownloadButton({
  onClick,
  groups,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>
  groups?: CognitoGroup[]
}) {
  return (
    <ActionButton
      icon={ArrowDownToLine}
      text="Download"
      onClick={onClick}
      groups={groups}
    />
  )
}

export function LoadingButton() {
  return <ActionButton text="Loading..." disabled={true} />
}

export function ComingSoonButton() {
  return <ActionButton text="Coming soon..." disabled={true} />
}
