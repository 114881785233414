import { debugLog } from '../debug'
import { MemberModel, ModelBase } from '../types/model'

let currentMember: MemberModel | undefined = undefined

export function getCurrentMember() {
  if (!currentMember) {
    throw new Error('Current member has not be set')
  }

  return currentMember
}

export function getCurrentMemberId() {
  const member = getCurrentMember()

  if (!member.fields.id) {
    throw new Error('Current memberId is not set')
  }

  return member.fields.id
}

export function isCurrentMember(member: MemberModel) {
  return member.fields.id === getCurrentMemberId()
}

export function setCurrentMember(member: MemberModel | undefined) {
  debugLog('setCurrentMember', () => [{ member }])
  currentMember = member
}

export async function setModel<T extends ModelBase>(
  instance: any,
  model: T,
  callback?: (model: T) => Promise<void>
) {
  const { fields } = model

  if (instance.id) {
    fields.id = instance.id
  }

  for (const field in fields) {
    fields[field] = instance[field]
  }

  if (callback) {
    await callback(model)
  }

  return model
}
