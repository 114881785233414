import { atom } from 'jotai'
import { Venue } from '../../models'
import {
  createVenue,
  getVenue,
  initVenue,
  listVenues,
} from '../../services/venue'
import { VenueModel } from '../../types/model'
import { getModelAtoms } from '../common'

const {
  refreshAtom: venueRefreshAtom,
  listAtom: venueListAtom,
  mapAtom: venueMapAtom,
  countAtom: venueCountAtom,
} = getModelAtoms<Venue, VenueModel>(
  'venue',
  initVenue,
  createVenue,
  listVenues,
  getVenue
)

const venueOptionsAtom = atom(async (get) => {
  const models = await get(venueListAtom)

  return models.map(({ fields: { id, name, room } }) => ({
    value: id || '',
    text: name ? `${name}${room ? ` - ${room}` : ''}` : '',
  }))
})

export {
  venueCountAtom,
  venueListAtom,
  venueMapAtom,
  venueOptionsAtom,
  venueRefreshAtom,
}
