import {
  NavigateProps,
  Navigate as RouterNavigate,
  useLocation,
} from 'react-router-dom'

export function Navigate({ to, ...props }: NavigateProps) {
  const { search } = useLocation()
  return <RouterNavigate to={to + search} {...props} />
}
