import { Authenticator } from '@aws-amplify/ui-react'
import { Amplify, DataStore, Hub } from 'aws-amplify'
import { DevTools as JotaiDevTools } from 'jotai-devtools'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router'
import awsconfig from './aws-exports'
import { DevTools } from './components/common/devtools'
import { Logo } from './components/common/logo'
import { debugLog, init, isDebug } from './debug'
import { router } from './router'

import '@aws-amplify/ui/dist/styles.css'
import '@fontsource/inter'

import 'react-datepicker/dist/react-datepicker.css'

import { setCurrentMember } from './services'
import './styles/global.css'
import './styles/index.css'

init()

Amplify.configure(awsconfig)

Hub.listen('auth', async ({ payload }) => {
  debugLog('hub', () => ['auth', payload.event, { payload }])

  if (payload.event === 'signOut') {
    debugLog('hub', () => ['DataStore.clear()'])
    setCurrentMember(undefined)
    await DataStore.clear()
  }
})

if (isDebug('hub')) {
  Hub.listen('datastore', async ({ payload }) => {
    debugLog('hub', () => ['datastore', payload.event, { payload }])
  })
}

const Header = () => {
  return (
    <div className="authenticator__header">
      <Logo />
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Authenticator components={{ Header }} hideSignUp={true} variation="modal">
      <JotaiDevTools />
      <DevTools />
      <RouterProvider router={router} />
    </Authenticator>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
