import qs from 'query-string'
import { CommonProps } from './types/props'

export let SHOW_DEBUG_PANEL = false
export const ALL_DEBUG_MODES: string[] = []
export const ACTIVE_DEBUG_MODES: string[] = []

let initialized = false

/** Initializes debug modes; looks for `debug=mode1,mode2` in the query string. */
export function init() {
  if (initialized) return

  const query = qs.parse(window.location.search, { arrayFormat: 'comma' })

  if ('debug' in query || isNonProduction()) {
    SHOW_DEBUG_PANEL = true

    if ('debug' in query) {
      ACTIVE_DEBUG_MODES.push(...[query.debug as string].flat())
    }

    if (!isDebugAll()) {
      const modes = ACTIVE_DEBUG_MODES.join(', ')
      console.log(
        `%c🐞 Debugging enabled for modes:`,
        'font-weight: 600',
        modes ? modes : '(none)'
      )
    } else {
      console.log('%c🐞 Debugging enabled for all modes.', 'font-weight: 600')
    }
  }

  initialized = true
}

/**
 * Gets a boolean indicating whether code is running on localhost or in the live
 * Stage environment.
 */
function isNonProduction() {
  const { origin } = window.location
  return origin.includes('localhost') || origin.includes('stginteractive')
}

/** Gets a boolean indicating whether all debug modes should be active. */
function isDebugAll() {
  return ACTIVE_DEBUG_MODES.includes('true') || ACTIVE_DEBUG_MODES.includes('*')
}

/**
 * Returns true if any of the debug modes are active.
 *
 * @param modes One or more debug mode strings; as arguments.
 * @returns
 */
export function isDebug(...modes: string[]): boolean {
  modes = captureModes(modes)
  init()

  return (
    isDebugAll() ||
    ACTIVE_DEBUG_MODES.some((mode) => mode && modes.includes(mode))
  )
}

/**
 * Logs a message to the console when one or more debug modes are active.
 *
 * @param modes A single debug mode string, or an array of multiple debug mode
 *   strings.
 * @param logArgsFn A function that returns an array of arguments to pass into
 *   `console.log`; only executed when debug mode(s) are active.
 */
export function debugLog(
  mode: string | string[],
  logArgsFn: () => any[]
): void {
  const modes = captureModes([mode].flat())

  if (isDebug(...modes)) {
    console.log(
      ...[`%c🐞 ${modes.join(', ')}`, 'font-weight: 600', ...logArgsFn()]
    )
  }
}

/**
 * Logs a table-able object to the console when one or more debug modes are
 * active.
 *
 * @param mode A single debug mode string, or any array of multiple debug mode
 *   strings.
 * @param table Object that can be represented as a table.
 */
export function debugTable(mode: string | string[], table: any) {
  const modes = captureModes([mode].flat())

  if (isDebug(...modes)) {
    console.table(table)
  }
}

/**
 * Captures debug modes as they are used by `logDebug` or `isDebug` calls at
 * runtime so that they can be made available to the Debug panel.
 */
function captureModes(modes: string[]) {
  modes
    .filter((m) => !ALL_DEBUG_MODES.includes(m))
    .forEach((m) => ALL_DEBUG_MODES.push(m))
  return modes
}

export function debugSave(...args: any[]) {
  debugLog('save', () => [...args])
}

export function debugJotai(logArgsFn: () => any[]) {
  debugLog('jotai', logArgsFn)
}

export function debugInit(logArgsFn: () => any[]) {
  debugLog('init', logArgsFn)
}

export function DebugPanel({ children }: CommonProps) {
  return <>{SHOW_DEBUG_PANEL ? children : null}</>
}
