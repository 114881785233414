import { Auth } from 'aws-amplify'
import cx from 'classnames'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router'
import { debugLog } from '../debug'
import { useBreakpoint } from '../hooks/interface/useBreakpoint'
import { useAppInitNew } from '../hooks/useAppInitNew'
import { LoadingIcon } from './common/loading'
import { Logo } from './common/logo'
import { Nav } from './common/nav'
import s from './root.module.css'

export function Root() {
  debugLog('root', () => ['root:start'])

  const ready = useAppInitNew()
  const { breakpointName, navAsHeader, navAsSidebar } = useBreakpoint()
  const [showReset, setShowReset] = useState(false)

  useEffect(() => {
    const id = setTimeout(() => {
      if (!ready) setShowReset(true)
    }, 10000)
    return () => clearTimeout(id)
  }, [ready])

  debugLog('root', () => ['root:ready', breakpointName, ready])

  return ready ? (
    <motion.div
      className={cx(
        s.container,
        s[breakpointName],
        {
          [s.navAsHeader]: navAsHeader,
          [s.navAsSidebar]: navAsSidebar,
        },
        `viewport__${breakpointName}`
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {navAsHeader && <Nav />}

      <div className={s.main}>
        <Outlet />
      </div>

      {navAsSidebar && <Nav />}
    </motion.div>
  ) : (
    <div className={s.loading}>
      <div className={s.logoSpinner}>
        <Logo size="large" />
        <LoadingIcon />
      </div>

      {showReset && (
        <motion.div
          className={s.reset}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: 'spring',
            damping: 24,
            stiffness: 500,
          }}
        >
          The app is taking longer than expected to load.
          <button className={s.resetButton} onClick={resetApp}>
            Log Out to Reset
          </button>
        </motion.div>
      )}
    </div>
  )

  async function resetApp() {
    await Auth.signOut()
  }
}
