import { setCurrentMember } from '../services'
import { getCurrentMember } from '../services/member'
import { getAtomWithRefresh } from './common'

/** Holds the MemberModel for the current user. */
export const currentMemberAtom = getAtomWithRefresh(async (get) => {
  const member = await getCurrentMember()
  setCurrentMember(member)
  return member
}, 'currentMember')
