import { Amplify } from 'aws-amplify'
import config from '../config'

let ENV: string | null = null

export enum Environment {
  staging = 'staging',
  production = 'production',
}

export function getEnv(): string {
  if (ENV) {
    if (ENV in config === false) {
      throw new Error(`Environment ${ENV} is not recognized`)
    }
    return ENV
  }

  const { aws_user_files_s3_bucket: bucket } = Amplify['_config']
  const parts = bucket.split('-')

  ENV = parts.length > 0 ? parts[parts.length - 1] : ''

  return getEnv() || ''
}

export function isProduction() {
  return getEnv() === Environment.production
}

export function getStoragePublicDomain() {
  const env = getEnv()

  if (!env) {
    throw new Error('env is not defined')
  }

  return config[env].cdn.publicDomain
}
