import { DataStore, Hub } from 'aws-amplify'
import { useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { debugLog } from '../debug'
import { setCurrentMember } from '../services'
import { getCurrentMember } from '../services/member'
import { currentMemberAtom } from '../state/currentMember'

export function useAppInitNew() {
  const [ready, setReady] = useState<boolean>(false)
  const refreshCurrentMemberAtom = useSetAtom(currentMemberAtom)

  useEffect(() => {
    const unsubscribe = Hub.listen('datastore', async ({ payload }) => {
      if (payload.event === 'ready') {
        // When datastore is ready, get the current user from Auth and match
        // with their Member record in Dynamo so we can store it globally.
        const member = await getCurrentMember()
        setCurrentMember(member)

        // Refresh the atom holding the current member in memory.
        refreshCurrentMemberAtom()

        debugLog('hub', () => ['useAppInitNew', 'currentMember', { member }])

        // Signal ready to render app.
        setReady(true)
      }
    })

    DataStore.start()

    return unsubscribe
  })

  return ready
}
