import { DataStore, SortDirection } from 'aws-amplify'
import { isString } from 'lodash'
import { setModel } from '.'
import { Event, EventType } from '../models'
import { EventFields, EventModel, Field } from '../types/model'
import { Option } from '../types/options'
import { createSeries } from './series'
import { createVenue } from './venue'
import { formatDayMonthDate, formatShortTime } from '../util/dates'

export async function getEvent(eventId: string) {
  const events = await DataStore.query(Event, (e) => e.id.eq(eventId))
  return events[0]
}

export async function listEvent({ seasonId }: { seasonId?: string }) {
  if (!seasonId) return []

  const events = await DataStore.query(Event, (e) => e.seasonId.eq(seasonId), {
    sort: (s) =>
      s.date(SortDirection.ASCENDING).startTime(SortDirection.ASCENDING),
  })

  return events
}

export function initEvent(initialFieldValues: EventFields = {}): EventModel {
  return {
    fields: {
      type: undefined,
      date: undefined,
      warmUpTime: undefined,
      startTime: undefined,
      endTime: undefined,
      roles: undefined,
      notes: undefined,
      venueId: undefined,
      warmUpVenueId: undefined,
      seasonId: undefined,
      seriesIds: undefined,
      ...initialFieldValues,
    },
    rels: {
      venue: null,
      warmUpVenue: null,
      series: [],
    },
    meta: {
      dateHeader: '',
      dateTimeHeader: '',
      dateHasMultiplePerformances: false,
    },
  }
}

export async function createEvent(eventOrId: Event | string) {
  const event = isString(eventOrId) ? await getEvent(eventOrId) : eventOrId
  const model = initEvent()

  return setModel(event, model, async (model) => {
    const { fields, rels, meta } = model

    if (fields.date) {
      meta.dateHeader = formatDayMonthDate(fields.date)
    }

    if (fields.date && fields.startTime) {
      meta.dateTimeHeader = `${formatDayMonthDate(
        fields.date
      )} ${formatShortTime(fields.startTime)}`
    }

    if (fields.warmUpVenueId) {
      rels.warmUpVenue = await createVenue(fields.warmUpVenueId)
    }

    if (fields.seriesIds) {
      for (const seriesId of fields.seriesIds) {
        rels.series?.push(await createSeries(seriesId))
      }
    }

    if (fields.venueId) {
      rels.venue = await createVenue(fields.venueId)
    } else if (rels.series?.length === 1) {
      rels.venue = await createVenue(rels.series[0].fields.venueId)
    }
  })
}

export async function saveEvent(model: EventModel) {
  const { fields } = model

  const event = fields.id
    ? Event.copyOf(await getEvent(fields.id), (e) => Object.assign(e, fields))
    : new Event(fields)

  await DataStore.save(event)
}

export async function deleteEvent(model: EventModel) {
  const { fields } = model

  if (fields.id) {
    await DataStore.delete(await getEvent(fields.id))
  }
}

const {
  REHEARSAL,
  PIANO_DRESS_REHEARSAL,
  DRESS_REHEARSAL,
  CONDUCTOR_REHEARSAL,
  ORCHESTRA_REHEARSAL,
  PERFORMANCE,
  SOCIAL_GATHERING,
  COUNCIL_MEETING,
  AUDITIONS,
} = EventType

export const REHEARSAL_TYPES = [
  REHEARSAL,
  PIANO_DRESS_REHEARSAL,
  ORCHESTRA_REHEARSAL,
  DRESS_REHEARSAL,
  CONDUCTOR_REHEARSAL,
]
export const OTHER_EVENT_TYPES = [SOCIAL_GATHERING, COUNCIL_MEETING, AUDITIONS]

export const EventTypeName = {
  [REHEARSAL]: 'Rehearsal',
  [PIANO_DRESS_REHEARSAL]: 'Piano Dress',
  [DRESS_REHEARSAL]: 'Dress Rehearsal',
  [CONDUCTOR_REHEARSAL]: 'Conductor Rehearsal',
  [ORCHESTRA_REHEARSAL]: 'Orchestra Rehearsal',
  [PERFORMANCE]: 'Performance',
  [SOCIAL_GATHERING]: 'Social Gathering',
  [COUNCIL_MEETING]: 'Council Meeting',
  [AUDITIONS]: 'Auditions',
}

export function getEventTypeName(type?: Field<EventType>) {
  return type ? EventTypeName[type] : null
}

export function getRehearsalEventTypeOptions(): Option[] {
  return REHEARSAL_TYPES.map((type) => ({
    value: type,
    text: EventTypeName[type],
  }))
}
