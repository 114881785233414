import { useAtomValue } from 'jotai'
import { useParams } from 'react-router'
import { seasonAtomFamily } from '../../state/model/season'
import { SeasonModel } from '../../types/model'

export function useRouteSeason(): SeasonModel | undefined {
  const { seasonId } = useParams()
  const season = useAtomValue(seasonAtomFamily(seasonId))
  return 'id' in season.fields ? season : undefined
}
