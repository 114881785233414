import { Atom, Getter, PrimitiveAtom, WritableAtom, atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { AtomFamily } from 'jotai/vanilla/utils/atomFamily'
import isEqual from 'lodash/isEqual'
import { debugJotai } from '../debug'
import { ModelBase } from '../types/model'
import { createMap } from '../util/map'
import { makeOptions } from '../util/options'

/**
 * Gets a common set of atoms for managing a list of ModelBase-derived records
 * that require no parameter input. Applies debug labeling for Jotai DevTools
 * and outputs to console when debug=jotai.
 *
 * @param name Name of the entity to use for debugging/logging.
 * @param initFn Function returning an initial unpopulated model instance.
 * @param createFn Function returning a populated model instance.
 * @param listFn Function for returning raw records from Amplify DataStore.
 * @param getFn Function for getting a single raw record from Amplify DataStore.
 * @returns
 */
export function getModelAtoms<Record, Model extends ModelBase>(
  name: string,
  initFn: () => Model,
  createFn: (record: Record, get?: Getter) => Promise<Model>,
  listFn: () => Promise<Record[]>,
  getFn: (id: string) => Promise<Record>
) {
  const refreshAtom = getRefreshAtom(name)
  const listAtom = getListAtom(name, createFn, listFn, refreshAtom, {})
  const countAtom = getCountAtom(name, listAtom)
  const mapAtom = getMapAtom(name, listAtom)
  const optionsAtom = getOptionsAtom(name, listAtom)

  const modelAtomFamily = atomFamily((id?: string) =>
    // getModelAtom(name, initFn, createFn, getFn, {}, id)
    getModelAtom(name, initFn, mapAtom, {}, id)
  )

  return {
    /** Atom holding a list of model instances. */
    listAtom,
    /**
     * Atom holding a counter used to trigger refresh. Can also be used as 'key'
     * of a Table component to trigger rerender.
     */
    refreshAtom,
    /** Atom holding the total count of model instances. */
    countAtom,
    /** Atom holding a mapping of model id to instance for fast lookup. */
    mapAtom,
    /** Atom family for getting a single model instance from the underlying list. */
    modelAtomFamily,
    /** Atom holding a list of model options for a dropdown. */
    optionsAtom,
  }
}

/**
 * Gets a common set of atoms for managing a list of ModelBase-derived records
 * that require parameter input. Applies debug labeling for Jotai DevTools and
 * outputs to console when debug=jotai.
 *
 * @param name Name of the entity to use for debugging/logging.
 * @param initFn Function returning an initial unpopulated model instance.
 * @param createFn Function returning a populated model instance.
 * @param listFn Function for returning raw records from Amplify DataStore.
 * @param getFn Function for getting a single raw record from Amplify DataStore.
 * @returns
 */
export function getModelAtomFamilies<
  Record,
  Model extends ModelBase,
  ListParams,
>(
  name: string,
  initFn: () => Model,
  createFn: (
    record: Record,
    get: Getter,
    listParams: ListParams
  ) => Promise<Model>,
  listFn: (listParams: ListParams, get: Getter) => Promise<Record[]>,
  getFn: (id: string) => Promise<Record>
) {
  const refreshAtomFamily = atomFamily(
    (listParams: ListParams) => getRefreshAtom(name, listParams),
    isEqual
  )

  const listAtomFamily = atomFamily(
    (listParams: ListParams) =>
      getListAtom(
        name,
        createFn,
        listFn,
        refreshAtomFamily(listParams),
        listParams
      ),
    isEqual
  )

  const countAtomFamily = atomFamily(
    (listParams: ListParams) =>
      getCountAtom(name, listAtomFamily(listParams), listParams),
    isEqual
  )

  const mapAtomFamily = atomFamily(
    (listParams: ListParams) =>
      getMapAtom(name, listAtomFamily(listParams), listParams),
    isEqual
  )

  const optionsAtomFamily = atomFamily(
    (listParams: ListParams) =>
      getOptionsAtom(name, listAtomFamily(listParams), listParams),
    isEqual
  )

  const modelAtomFamily = atomFamily(
    (paramsWithId: ListParams & { id?: string }) => {
      const { id, ...listParams } = paramsWithId
      // return getModelAtom(
      //   name,
      //   initFn,
      //   createFn,
      //   getFn,
      //   listParams as ListParams,
      //   id
      // )
      return getModelAtom(
        name,
        initFn,
        mapAtomFamily(listParams as ListParams),
        listParams as ListParams,
        id
      )
    },
    isEqual
  )

  return {
    refreshAtomFamily,
    listAtomFamily,
    countAtomFamily,
    mapAtomFamily,
    optionsAtomFamily,
    modelAtomFamily,
  }
}

/**
 * Gets an atom whose setter can be used to refresh its data by calling it
 * getter again.
 *
 * @param fn Getter function.
 * @param label Atom label for debugging.
 * @param refreshAtom Optionally pass in a refresh counter atom; if not provided
 *   one will be created internally.
 * @returns
 */
export function getAtomWithRefresh<T>(
  fn: (get: Getter) => Promise<T>,
  label: string,
  refreshAtom?: PrimitiveAtom<number>
) {
  const refresh = refreshAtom || getRefreshAtom(label)

  const a = atom(
    (get) => {
      get(refresh)
      const result = fn(get)
      debugJotai(() => [label, { result }])
      return fn(get)
    },
    (_, set) => set(refresh, (i) => i + 1)
  )

  a.debugLabel = label

  return a
}

export function getLabel(name: string, type: string, params?: any) {
  return params ? `${name}${type}[${JSON.stringify(params)}]` : `${name}${type}`
}

export function getAtom<T>(label: string, defaultValueOrGetter: T) {
  const a = atom(defaultValueOrGetter)
  a.debugLabel = label
  return a
}

export function getRefreshAtom(name: string, params?: any) {
  return getAtom(getLabel(name, 'ListRefresh', params), 0)
}

function getListAtom<Record, Model extends ModelBase, ListParams>(
  name: string,
  createFn: (
    record: Record,
    get: Getter,
    listParams: ListParams
  ) => Promise<Model>,
  listFn: (params: ListParams, get: Getter) => Promise<Record[]>,
  refreshAtom: PrimitiveAtom<number>,
  params: ListParams
) {
  return getAtomWithRefresh(
    async (get) => {
      const models: Model[] = []
      const records = await listFn(params, get)

      for (const record of records) {
        models.push(await createFn(record, get, params))
      }

      return models
    },
    getLabel(name, 'List', params),
    refreshAtom
  )
}

function getCountAtom<Model extends ModelBase, ListParams>(
  name: string,
  listAtom: WritableAtom<Promise<Model[]>, [], void>,
  params?: ListParams
) {
  const label = getLabel(name, 'Count', params)

  const a = atom(async (get) => {
    const list = await get(listAtom)
    const count = list.length
    debugJotai(() => [label, { count }])
    return count
  })

  a.debugLabel = label

  return a
}

function getMapAtom<Model extends ModelBase, ListParams>(
  name: string,
  listAtom: WritableAtom<Promise<Model[]>, [], void>,
  params?: ListParams
) {
  const label = getLabel(name, 'Map', params)

  const a = atom(async (get) => {
    const models = await get(listAtom)
    const map = createMap(models, (m) => m.fields.id as string)
    debugJotai(() => [label, { map }])
    return map
  })

  a.debugLabel = label

  return a
}

function getOptionsAtom<Model extends ModelBase, ListParams>(
  name: string,
  listAtom: WritableAtom<Promise<Model[]>, [], void>,
  params?: ListParams
) {
  const label = getLabel(name, 'Options', params)

  const a = atom(async (get) => {
    const models = await get(listAtom)
    const options = makeOptions(models)

    debugJotai(() => [label, { options }])

    return options
  })

  a.debugLabel = label

  return a
}

// function getModelAtom<Record, Model extends ModelBase, ListParams>(
//   name: string,
//   initFn: () => Model,
//   createFn: (
//     record: Record,
//     get: Getter,
//     listParams: ListParams
//   ) => Promise<Model>,
//   getFn: (id: string) => Promise<Record>,
//   params: ListParams,
//   id?: string
// ) {
//   const label = getLabel(name, 'Model', { ...params, id })

//   const a = atom(async (get) => {
//     if (!id) return initFn()

//     const record = await getFn(id)
//     const model = await createFn(record, get, params)

//     debugJotai(() => [label, { model }])

//     return model
//   })

//   a.debugLabel = label

//   return a
// }

function getModelAtom<Model extends ModelBase, ListParams>(
  name: string,
  initFn: () => Model,
  mapAtom: Atom<
    Promise<{
      [key: string]: Model
    }>
  >,
  params?: ListParams,
  id?: string
) {
  const label = getLabel(name, 'Model', { ...params, id })

  const a = atom(async (get) => {
    if (!id) return initFn()

    const map = await get(mapAtom)

    if (id in map === false) {
      throw new Error(`${name} not found for id: ${id}`)
    }

    const model = map[id]

    debugJotai(() => [label, { model }])

    return model
  })

  a.debugLabel = label

  return a
}

export function getFilteredAtomFamily<Model extends ModelBase, ListParams>(
  name: string,
  listAtomFamily: AtomFamily<ListParams, Atom<Promise<Model[]>>>,
  filterFn: (params: ListParams) => (model: Model) => boolean,
  filterListParams: (params: ListParams) => any = (params) => params
) {
  return atomFamily((params: ListParams) => {
    const label = getLabel(name, 'List', params)

    const a = atom(async (get) => {
      const all = await get(listAtomFamily(filterListParams(params)))
      const list = all.filter(filterFn(params))
      debugJotai(() => [label, { list }])
      return list
    })

    a.debugLabel = label

    return a
  }, isEqual)
}
