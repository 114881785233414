import moment from 'moment'

const todayMoment = moment()

export function getTodayShortDate() {
  return moment(new Date()).format('YYYY-MM-DD')
}

export function formatLongDate(
  date?: Date | string | null,
  withYearIfNotCurrent: boolean = false
) {
  const dateMoment = moment(date)
  const withYear =
    withYearIfNotCurrent && todayMoment.year() !== dateMoment.year()

  return date
    ? dateMoment.format(`dddd, MMMM Do${withYear ? ' YYYY' : ''}`)
    : null
}

export function formatMediumDate(
  date?: Date | string | null,
  withYear: boolean = false
) {
  return date
    ? moment(date).format(`dddd, MMM Do${withYear ? ' YYYY' : ''}`)
    : null
}

export function formatDayMonthDate(date?: Date | string | null) {
  return date ? moment(date).format('ddd MMM D') : ''
}

export function formatMonthYear(date?: Date | string | null) {
  return date ? moment(date).format('MMMM YYYY') : null
}

export function formatRunDates(
  startDate?: string | null,
  endDate?: string | null,
  withYear: boolean = false
): string {
  const start = moment(startDate)
  const end = moment(endDate)

  const sameDay = start.day() === end.day()
  const sameMonth = start.month() === end.month()

  if (sameMonth && sameDay) {
    return formatDate(start, withYear ? 'MMMM D, YYYY' : 'MMMM D')
  } else if (sameMonth) {
    return `${formatDate(start, 'MMMM D')} - ${formatDate(
      end,
      withYear ? 'D, YYYY' : 'D'
    )}`
  } else {
    return `${formatDate(start, 'MMMM D')} - ${formatDate(
      end,
      withYear ? 'MMMM D, YYYY' : 'MMMM D'
    )}`
  }
}

function formatDate(date: moment.Moment, format: string) {
  return date.isValid() ? date.format(format) : '(tbd)'
}

function timeMoment(time?: string | null) {
  return moment(time, [moment.ISO_8601, 'HH:mm'])
}

export function formatTime(time?: string | null) {
  return time ? timeMoment(time).format('h:mm A') : '(tbd)'
}

export function formatShortTime(time?: string | null) {
  if (!time) return '(tbd)'

  const timeMom = timeMoment(time)
  const hours = timeMom.format('h')
  const mins = timeMom.format('mm')
  const ampm = timeMom.format('a').charAt(0)

  return mins === '00' ? `${hours}${ampm}` : `${hours}:${mins}${ampm}`
}

export function formatTimeRange(
  startTime?: string | null,
  endTime?: string | null
) {
  const start = timeMoment(startTime)
  const end = timeMoment(endTime)

  const startDisplay = start.isValid() ? start.format('h:mm') : 'TBD'
  const endDisplay = end.isValid() ? end.format('h:mm A') : 'TBD'

  return startDisplay === 'TBD' && endDisplay === 'TBD'
    ? 'TBD'
    : `${startDisplay} - ${endDisplay}`
}

export function guessNextRehearsalDate(previous: string) {
  const date = moment(previous)
  return date.add(1, 'week').format('YYYY-MM-DD')
}
