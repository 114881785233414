import { Option } from '../types/options'

export function makeOptions(
  list: any[],
  valueProp: string = 'id',
  textProp: string = 'name'
): Option[] {
  return list.map(({ fields }) => ({
    value: fields[valueProp],
    text: fields[textProp],
  }))
}
