import { Getter, atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import groupBy from 'lodash/groupBy'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { debugJotai } from '../../debug'
import { Role } from '../../models'
import { setModel } from '../../services'
import {
  initCommitment,
  listCommitment,
  listCommitmentsForSeries,
} from '../../services/commitment'
import {
  CommitmentModel,
  EventModel,
  MemberModel,
  PerformanceSingerCount,
  SectionCountsModel,
  SeriesCommitmentMetadata,
  SeriesModel,
  SeriesPerformanceSectionCounts,
} from '../../types/model'
import {
  SeasonMemberParams,
  SeasonParam,
  SeasonSeriesParams,
  SeriesParam,
} from '../../types/params'
import { createMap } from '../../util/map'
import { getAtomWithRefresh, getLabel, getRefreshAtom } from '../common'
import { todayAtom } from '../today/today'
import { seriesPerformanceListAtomFamily } from './event'
import { memberAtomFamily, memberMapAtom } from './member'
import { rosterAtomFamily } from './roster'
import {
  seriesAtomFamily,
  seriesListAtomFamily,
  seriesMapAtomFamily,
} from './series'

/**
 * Use a common refresh atom so that all commitment atoms are refreshed together
 * when any of them are refreshed.
 */
const refreshAtom = getRefreshAtom('commitments')

/**
 * Atom family for getting a list of commitments for a season and member; use
 * its setter to refresh the list.
 */
export const seasonMemberCommitmentListAtomFamily = atomFamily(
  (params: SeasonMemberParams) =>
    getAtomWithRefresh<CommitmentModel[]>(
      async (get) => {
        const { seasonId, memberId } = params

        if (!seasonId || !memberId) {
          return []
        }

        // Get season roster to make sure the member is active for the season
        const roster = await get(rosterAtomFamily({ seasonId }))
        if (!roster?.meta.allMemberIds.includes(memberId)) {
          // If the member is not on the season roster, return empty array
          return []
        }

        // Get member model and the series list & map for the season
        const [member, seriesList, seriesMap] = await Promise.all([
          get(memberAtomFamily(memberId)),
          get(seriesListAtomFamily({ seasonId })),
          get(seriesMapAtomFamily({ seasonId })),
        ])

        // Get a list of seriesIds for which the member's section is participating
        const seriesIds = seriesList
          .filter((series) => {
            const role = member.fields.role
            return role && series.fields.roles.includes(role)
          })
          .map((s) => s.fields.id as string)

        // Fetch commitment records for the member and relevant series;
        // create a map of results for fast/easy lookup
        const commitments = await listCommitment(memberId, seriesIds)
        const commitmentMap = createMap(commitments, (c) => c.seriesId)

        // Get the current 'today' date; used to flag series already past
        const todayMoment = moment(get(todayAtom))
        const models: CommitmentModel[] = []

        // For each relevant series, create a CommitmentModel
        for (const seriesId of seriesIds) {
          const model = initCommitment(memberId, seriesId)

          if (seriesId in commitmentMap) {
            setModel(commitmentMap[seriesId], model)
          }

          const series = seriesMap[seriesId]
          const { end } = series.fields

          model.rels = {
            series,
            performances: await get(
              seriesPerformanceListAtomFamily({ seasonId, seriesId })
            ),
          }

          model.meta.hasPassed = moment(end).isBefore(todayMoment)

          models.push(model)
        }

        // Sort commitments by series start date
        const sorted = sortBy(
          models,
          (c) => seriesMap[c.fields.seriesId].fields.start
        )

        return sorted
      },
      getLabel('memberCommitment', 'List', params),
      refreshAtom
    ),
  isEqual
)

/**
 * Atom family for tracking aggregated commitment counts by section for each
 * series; displayed on series cards on the Season page, Series tab.
 */
export const seriesSectionCommitmentCountsAtomFamily = atomFamily(
  (params: SeriesParam) =>
    getAtomWithRefresh<SeriesCommitmentMetadata>(
      async (get) => {
        const meta: SeriesCommitmentMetadata = {
          committedCount: 0,
          commitments: {
            SOPRANO: 0,
            ALTO: 0,
            TENOR: 0,
            BASS: 0,
          },
        }

        const { seriesId } = params
        if (!seriesId) {
          return meta
        }

        const commitments = await listCommitmentsForSeries(seriesId)
        const committedMemberIds = commitments
          .filter((c) => c.allPerformances || c.somePerformances)
          .map((c) => c.memberId)

        const memberMap = await get(memberMapAtom)
        const committedMembers = committedMemberIds.map(
          (memberId) => memberMap[memberId]
        )

        const SOPRANO = getSectionCount(committedMembers, Role.SOPRANO)
        const ALTO = getSectionCount(committedMembers, Role.ALTO)
        const TENOR = getSectionCount(committedMembers, Role.TENOR)
        const BASS = getSectionCount(committedMembers, Role.BASS)

        meta.commitments = { SOPRANO, ALTO, TENOR, BASS }
        meta.committedCount = SOPRANO + ALTO + TENOR + BASS

        return meta
      },
      getLabel('seriesCommitment', 'Metadata', params),
      refreshAtom
    ),
  isEqual
)

/** Gets a count of members matching the section/role. */
const getSectionCount = (members: MemberModel[], role: Role) => {
  return members.filter(({ fields }) => fields.role === role).length
}

/** Gets section singer commitment counts for all performances of a season. */
export const seasonPerformanceSectionCommitmentCountsAtomFamily = atomFamily(
  (params: SeasonParam) =>
    atom(async (get) => {
      const { seasonId } = params

      if (!seasonId) {
        throw new Error('seasonId must be defined')
      }

      const seriesList = await get(seriesListAtomFamily({ seasonId })),
        seasonCounts: SeriesPerformanceSectionCounts = [
          { fields: { section: Role.SOPRANO, counts: [] } },
          { fields: { section: Role.ALTO, counts: [] } },
          { fields: { section: Role.TENOR, counts: [] } },
          { fields: { section: Role.BASS, counts: [] } },
          { fields: { section: 'TOTAL', counts: [] } },
        ],
        seasonCountsMap = createMap(seasonCounts, (c) => c.fields.section)

      for (const series of seriesList) {
        const seriesId = series.fields.id,
          seriesCounts = await get(
            seriesPerformanceSectionCountsAtomFamily({ seasonId, seriesId })
          )

        for (const sectionCounts of seriesCounts) {
          seasonCountsMap[sectionCounts.fields.section].fields.counts.push(
            ...sectionCounts.fields.counts
          )
        }
      }

      debugJotai(() => [
        'seasonPerformanceSectionCommitmentCountsAtomFamily',
        { seasonCounts },
      ])

      return seasonCounts
    }),
  isEqual
)

/**
 * Atom family for tracking aggregated singer counts by section for each
 * performance of a series.
 */
export const seriesPerformanceSectionCountsAtomFamily = atomFamily(
  (params: SeasonSeriesParams) =>
    atom(async (get) => {
      const { seasonId, seriesId } = params

      if (!seasonId || !seriesId) {
        throw new Error('seasonId and seriesId must be defined')
      }

      let [series, roster, perfs, memMap] = await Promise.all([
        get(seriesAtomFamily({ seasonId, id: seriesId })),
        get(rosterAtomFamily({ seasonId })),
        get(seriesPerformanceListAtomFamily({ seasonId, seriesId })),
        get(memberMapAtom),
      ])

      if (!series) {
        throw new Error(`series is not defined for seriesId ${seriesId}`)
      } else if (!roster) {
        throw new Error(`roster is not defined for seasonId ${seasonId}`)
      }

      const groupedByDate = groupBy(perfs, (p) => p.fields.date)
      const dupeDates = Object.keys(groupedByDate).filter(
        (date) => groupedByDate[date].length > 1
      )
      perfs = perfs.map((p) => ({
        ...p,
        meta: {
          ...p.meta,
          dateHasMultiplePerformances: dupeDates.includes(p.fields.date!),
        },
      }))

      const { SOPRANO, ALTO, TENOR, BASS } = Role
      const {
        sopranoMemberIds: sMemIds,
        altoMemberIds: aMemIds,
        tenorMemberIds: tMemIds,
        bassMemberIds: bMemIds,
      } = roster.fields

      const [sopranos, altos, tenors, basses] = await Promise.all([
        getSectionCounts(get, series, perfs, SOPRANO, sMemIds, memMap),
        getSectionCounts(get, series, perfs, ALTO, aMemIds, memMap),
        getSectionCounts(get, series, perfs, TENOR, tMemIds, memMap),
        getSectionCounts(get, series, perfs, BASS, bMemIds, memMap),
      ])

      const totals = {
        fields: {
          section: 'TOTAL',
          counts: getSectionTotals(sopranos, altos, tenors, basses),
        },
      }

      const counts = [sopranos, altos, tenors, basses, totals]

      debugJotai(() => ['seriesPerformanceSectionCountsAtomFamily', { counts }])

      return counts
    }),
  isEqual
)

/** Gets section singer commitment counts for all performances in a series. */
const getSectionCounts = async (
  get: Getter,
  series: SeriesModel,
  performances: EventModel[],
  section: Role,
  memberIds: string[],
  memberMap: { [memberId: string]: MemberModel }
): Promise<SectionCountsModel<string>> => {
  const { seasonId } = series.fields
  if (!seasonId) {
    throw new Error('seasonId not defined for series')
  }

  // Get the current 'today' date; used to flag series already past
  const todayMoment = moment(get(todayAtom))

  let counts: PerformanceSingerCount[] = performances.map(
    ({ fields, meta }) => ({
      series,
      eventId: fields.id as string,
      date: fields.date as string,
      time: fields.startTime as string,
      header: meta.dateHasMultiplePerformances
        ? meta.dateTimeHeader
        : meta.dateHeader,
      count: 0,
      members: [],
      hasPassed: todayMoment.isAfter(moment(series.fields.end)),
    })
  )

  for (const memberId of memberIds) {
    const seasonCommitments = await get(
        seasonMemberCommitmentListAtomFamily({ seasonId, memberId })
      ),
      seriesCommitment = seasonCommitments.find(
        (c) => c.fields.seriesId === series.fields.id
      )

    if (seriesCommitment) {
      const { allPerformances, somePerformances, performanceEventIds } =
        seriesCommitment.fields

      counts = counts.map(
        ({
          series,
          eventId,
          date,
          time,
          header,
          count,
          members,
          hasPassed,
        }) => {
          const isCommitted =
            allPerformances ||
            (somePerformances && performanceEventIds.includes(eventId))

          return {
            series,
            eventId,
            date,
            time,
            header,
            count: isCommitted ? count + 1 : count,
            members: isCommitted
              ? [...members, memberMap[memberId]]
              : [...members],
            hasPassed,
          }
        }
      )
    }
  }

  return {
    fields: {
      section,
      counts,
    },
  }
}

/** Sums all section singer counts for a Total row. */
const getSectionTotals = (...sectionCounts: SectionCountsModel<string>[]) => {
  const totals = sectionCounts[0].fields.counts.map(
    ({ series, eventId, date, time, header, hasPassed }) => ({
      series,
      eventId,
      date,
      time,
      header,
      count: 0,
      members: [],
      hasPassed,
    })
  )

  const totalsMap = createMap(totals, (c) => c.eventId)

  for (const {
    fields: { counts },
  } of sectionCounts) {
    for (const { series, eventId, count } of counts) {
      totalsMap[eventId].series = series
      totalsMap[eventId].count += count
    }
  }

  return totals
}
