import { motion } from 'framer-motion'
import { Loader2 } from 'lucide-react'
import { useEffect, useState } from 'react'
import s from './loading.module.css'

export function LoadingIcon() {
  return (
    <motion.div
      className={s.container}
      animate={{ rotate: [0, 360] }}
      transition={{
        type: 'tween',
        duration: 2,
        repeat: Infinity,
        ease: 'linear',
        delay: 0,
      }}
    >
      <Loader2 color="var(--color-primary)" size="40px" strokeWidth={1} />
    </motion.div>
  )
}

export function LoadingPanel() {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return show ? (
    <motion.div
      className={s.panel}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <LoadingIcon />
    </motion.div>
  ) : null
}
