import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

/** Whether the hamburger menu overlay is visible. */
export const showOverlayAtom = atom(false)
showOverlayAtom.debugLabel = 'showOverlay'

/**
 * Whether off-roster members are visible on the Roster tabs; reveals column
 * allowing members to be added or removed from the current season roster.
 */
export const showOffSeasonRosterAtom = atomWithStorage(
  'showOffSeasonRoster',
  false
)
showOffSeasonRosterAtom.debugLabel = 'showOffSeasonRoster'

/**
 * Whether All series/performance commitments are displayed on the Summary tab,
 * or if limited to Current & Future.
 */
export const showAllCommitmentSeriesAtom = atomWithStorage(
  'showAllCommitmentSeries',
  false
)
showAllCommitmentSeriesAtom.debugLabel = 'showAllCommitmentSeries'
