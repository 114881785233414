import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { isEqual } from 'lodash'
import { Season } from '../../models'
import {
  createSeason,
  getSeason,
  initSeason,
  listSeason,
} from '../../services/season'
import { SeasonModel } from '../../types/model'
import { SeasonParam } from '../../types/params'
import { getLabel, getModelAtoms } from '../common'
import {
  seasonOtherEventListAtomFamily,
  seasonPerformanceListAtomFamily,
  seasonRehearsalListAtomFamily,
} from './event'
import { seriesListAtomFamily } from './series'

const {
  refreshAtom: seasonRefreshAtom,
  listAtom: seasonListAtom,
  mapAtom: seasonMapAtom,
  countAtom: seasonCountAtom,
  optionsAtom: seasonOptionsAtom,
  modelAtomFamily: seasonAtomFamily,
} = getModelAtoms<Season, SeasonModel>(
  'season',
  initSeason,
  createSeason,
  listSeason,
  getSeason
)

/** Season metadata including series, performance, rehearsal, and event counts. */
const seasonMetadataAtom = atomFamily(({ seasonId }: SeasonParam) => {
  const a = atom(async (get) => {
    const [seriesList, performanceList, rehearsalList, eventList] =
      await Promise.all([
        get(seriesListAtomFamily({ seasonId })),
        get(seasonPerformanceListAtomFamily({ seasonId })),
        get(seasonRehearsalListAtomFamily({ seasonId })),
        get(seasonOtherEventListAtomFamily({ seasonId })),
      ])

    return {
      seriesCount: seriesList.length,
      performanceCount: performanceList.length,
      rehearsalCount: rehearsalList.length,
      eventCount: eventList.length,
    }
  })

  a.debugLabel = getLabel('season', 'Metadata', { seasonId })

  return a
}, isEqual)

export {
  seasonAtomFamily,
  seasonCountAtom,
  seasonListAtom,
  seasonMapAtom,
  seasonMetadataAtom,
  seasonOptionsAtom,
  seasonRefreshAtom,
}
