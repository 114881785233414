import { Role } from '../models'
import { Field } from '../types/model'
import { Option } from '../types/options'

export const RoleName: { [index: string]: string } = {
  [Role.SOPRANO]: 'Soprano',
  [Role.ALTO]: 'Alto',
  [Role.TENOR]: 'Tenor',
  [Role.BASS]: 'Bass',
  [Role.DIRECTOR]: 'Director',
  [Role.PIANIST]: 'Pianist',
  [Role.LIASON]: 'Liason',
}

export const RoleNamePlural = {
  [Role.SOPRANO]: 'Sopranos',
  [Role.ALTO]: 'Altos',
  [Role.TENOR]: 'Tenors',
  [Role.BASS]: 'Basses',
  [Role.DIRECTOR]: 'Directors',
  [Role.PIANIST]: 'Pianists',
  [Role.LIASON]: 'Liasons',
}

export const SingerRoles = [Role.SOPRANO, Role.ALTO, Role.TENOR, Role.BASS]

export function getRoleOptions(): Option[] {
  return Object.keys(RoleName).map((role) => ({
    value: role,
    text: RoleName[role],
  }))
}

export function getSingerRoleOptions(): Option[] {
  return SingerRoles.map((role) => ({ value: role, text: RoleName[role] }))
}

export function getSingerRolePluralOptions(): Option[] {
  return SingerRoles.map((role) => ({
    value: role,
    text: RoleNamePlural[role],
  }))
}

export function getSinging(roles?: Field<Role[]>) {
  if (!roles) {
    return null
  }

  if (roles.length === 4) {
    return 'All Singers'
  }

  const list = roles.map((role) => RoleNamePlural[role])

  return list.length === 2 ? list.join(' & ') : list.join(', ')
}
