/**
 * Creates a map from the list using a function to derive each item's key in the
 * map; use for fast lookups.
 */
export function createMap<T>(list: T[], getKey: (item: T) => string) {
  return list.reduce(
    (map, item) => {
      map[getKey(item)] = item
      return map
    },
    {} as { [key: string]: T }
  )
}
