// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const EventType = {
  "REHEARSAL": "REHEARSAL",
  "PIANO_DRESS_REHEARSAL": "PIANO_DRESS_REHEARSAL",
  "ORCHESTRA_REHEARSAL": "ORCHESTRA_REHEARSAL",
  "PERFORMANCE": "PERFORMANCE",
  "SOCIAL_GATHERING": "SOCIAL_GATHERING",
  "COUNCIL_MEETING": "COUNCIL_MEETING",
  "AUDITIONS": "AUDITIONS",
  "DRESS_REHEARSAL": "DRESS_REHEARSAL",
  "CONDUCTOR_REHEARSAL": "CONDUCTOR_REHEARSAL"
};

const Role = {
  "SOPRANO": "SOPRANO",
  "ALTO": "ALTO",
  "TENOR": "TENOR",
  "BASS": "BASS",
  "DIRECTOR": "DIRECTOR",
  "PIANIST": "PIANIST",
  "LIASON": "LIASON"
};

const { Preferences, Roster, Commitment, Event, Parking, Venue, Post, Attendance, Series, Season, Member } = initSchema(schema);

export {
  Preferences,
  Roster,
  Commitment,
  Event,
  Parking,
  Venue,
  Post,
  Attendance,
  Series,
  Season,
  Member,
  EventType,
  Role
};