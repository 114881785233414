import { DataStore, SortDirection } from 'aws-amplify'
import { Getter } from 'jotai'
import isString from 'lodash/isString'
import { setModel } from '.'
import { Role, Series } from '../models'
import { seriesSectionCommitmentCountsAtomFamily } from '../state/model/commitment'
import {
  seriesPerformanceListAtomFamily,
  seriesRehearsalListAtomFamily,
} from '../state/model/event'
import { parkingMapAtom } from '../state/model/parking'
import { seasonMapAtom } from '../state/model/season'
import { venueMapAtom } from '../state/model/venue'
import { Field, SeriesModel } from '../types/model'
import { SingerRoles } from './role'

export async function getSeries(id: string): Promise<Series> {
  const series = await DataStore.query(Series, (s) => s.id.eq(id))
  return series[0]
}

export async function listSeries({ seasonId }: { seasonId?: string }) {
  if (!seasonId) return []

  const list = await DataStore.query(Series, (s) => s.seasonId.eq(seasonId), {
    sort: (s) => s.start(SortDirection.ASCENDING),
  })

  return list
}

export async function saveSeries(model: SeriesModel) {
  const { fields } = model

  const series = fields.id
    ? Series.copyOf(await getSeries(fields.id), (s) => Object.assign(s, fields))
    : new Series(fields)

  await DataStore.save(series)
}

export async function deleteSeries(model: SeriesModel) {
  const { fields } = model

  if (fields.id) {
    const series = await getSeries(fields.id)
    await DataStore.delete(series)
  }
  // Delete performances
  // Delete rehearsals
}

export function initSeries(seasonId?: Field<string>): SeriesModel {
  return {
    fields: {
      name: undefined,
      composer: undefined,
      conductor: undefined,
      start: undefined,
      end: undefined,
      roles: SingerRoles,
      notes: undefined,
      image: undefined,
      url: undefined,
      seasonId,
      venueId: undefined,
      parkingId: undefined,
    },
    rels: {
      season: null,
      venue: null,
      parking: null,
    },
    meta: {
      performanceCount: 0,
      rehearsalCount: 0,
      committedCount: 0,
      commitments: {
        [Role.SOPRANO]: 0,
        [Role.ALTO]: 0,
        [Role.TENOR]: 0,
        [Role.BASS]: 0,
      },
    },
  }
}

export async function createSeries(
  seriesOrId: Series | string,
  get?: Getter
): Promise<SeriesModel> {
  const series = isString(seriesOrId) ? await getSeries(seriesOrId) : seriesOrId
  const model = initSeries(series.seasonId)

  const seasonId = series.seasonId as string
  const seriesId = series.id

  const [
    seasonMap,
    venueMap,
    parkingMap,
    performanceList,
    rehearsalList,
    commitmentMetadata,
  ] = get
    ? await Promise.all([
        get(seasonMapAtom),
        get(venueMapAtom),
        get(parkingMapAtom),
        get(seriesPerformanceListAtomFamily({ seasonId, seriesId })),
        get(seriesRehearsalListAtomFamily({ seasonId, seriesId })),
        get(seriesSectionCommitmentCountsAtomFamily({ seriesId })),
      ])
    : [null, null, null, [], [], null]

  return setModel(series, model, async (model) => {
    const { fields, rels } = model
    const { seasonId, venueId, parkingId } = fields

    if (seasonId && seasonMap && seasonId in seasonMap) {
      rels.season = seasonMap[seasonId]
    }

    if (venueId && venueMap && venueId in venueMap) {
      rels.venue = venueMap[venueId]
    }

    if (parkingId && parkingMap && parkingId in parkingMap) {
      rels.parking = parkingMap[parkingId]
    }

    model.meta = {
      performanceCount: performanceList.length,
      rehearsalCount: rehearsalList.length,
      ...(commitmentMetadata || {
        committedCount: 0,
        commitments: {
          SOPRANO: 0,
          ALTO: 0,
          TENOR: 0,
          BASS: 0,
        },
      }),
    }
  })
}
