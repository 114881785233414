import { Member } from '../../models'
import {
  createMember,
  getMember,
  initMember,
  listMembers,
} from '../../services/member'
import { MemberModel } from '../../types/model'
import { getModelAtoms } from '../common'

const {
  refreshAtom: memberRefreshAtom,
  listAtom: memberListAtom,
  mapAtom: memberMapAtom,
  modelAtomFamily: memberAtomFamily,
  countAtom: memberCountAtom,
} = getModelAtoms<Member, MemberModel>(
  'member',
  initMember,
  createMember,
  listMembers,
  getMember
)

export {
  memberAtomFamily,
  memberCountAtom,
  memberListAtom,
  memberMapAtom,
  memberRefreshAtom,
}
