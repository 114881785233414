import { DebugPanel } from '../../debug'
import { useBreakpoint } from '../../hooks/interface/useBreakpoint'
import s from './devtools.module.css'

export function DevTools() {
  const { breakpointName } = useBreakpoint()

  return (
    <DebugPanel>
      <div className={s.container}>
        <div className={s.devtools}>{breakpointName}</div>
      </div>
    </DebugPanel>
  )
}
