import { atom } from 'jotai'
import { formatLongDate } from '../../util/dates'

/** Today's date. */
export const todayAtom = atom(new Date())
todayAtom.debugLabel = 'today'

/** Today's date for display; ex: Thursday, January 14 */
export const todayDisplayAtom = atom((get) =>
  formatLongDate(get(todayAtom), true)
)
todayDisplayAtom.debugLabel = 'todayDisplay'
