import { useMediaQuery } from 'react-responsive'
import { BreakpointName, getBreakpointQuery } from '../../concerns/breakpoints'

const { BigScreen, LargeScreen, MediumScreen, SmallScreen } = BreakpointName

export function useBreakpoint() {
  const isBigScreen = useMediaQuery(getBreakpointQuery('eq', BigScreen))
  const isLargeScreen = useMediaQuery(getBreakpointQuery('eq', LargeScreen))
  const isMediumScreen = useMediaQuery(getBreakpointQuery('eq', MediumScreen))
  const isSmallScreen = useMediaQuery(getBreakpointQuery('eq', SmallScreen))

  const breakpointName = isBigScreen
    ? BigScreen.toString()
    : isLargeScreen
    ? LargeScreen.toString()
    : isMediumScreen
    ? MediumScreen.toString()
    : isSmallScreen
    ? SmallScreen.toString()
    : 'undefined'

  const navAsSidebar = isBigScreen
  const navAsHeader = isLargeScreen || isMediumScreen || isSmallScreen

  return {
    isBigScreen,
    isLargeScreen,
    isMediumScreen,
    isSmallScreen,
    breakpointName,
    navAsSidebar,
    navAsHeader,
  }
}
