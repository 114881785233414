import { useMatches } from 'react-router'
import { debugLog } from '../../debug'
import { NavTree } from '../../types/route'
import { getNavTree } from '../../util/route'
import { useCurrentSeason } from '../model/useCurrentSeason'
import { useRouteSeason } from '../model/useRouteSeason'

/**
 * Returns a 2-level hierarchy of top level pages routes and their child tab
 * routes, resolving seasonId identifiers based on the currently active season.
 * Used for rendering nav header/sidebar links, tabs, and the page hiearchy that
 * appears in overlay on devices.
 *
 * @param level Either 'nav' or 'tabs'.
 * @returns
 */
export function useNavRoutes(level: 'nav' | 'tabs'): NavTree {
  const matches = useMatches()

  const routeSeason = useRouteSeason()
  const currentSeason = useCurrentSeason()
  const season = routeSeason || currentSeason

  const unresolved = getNavTree()
  const resolved = unresolved.map(({ path, tabs, ...rest }) => {
    path = withSeason(path)

    return {
      ...rest,
      path,
      tabs: tabs?.map(({ path, ...rest }) => {
        path = withSeason(path)

        return {
          ...rest,
          path: withSeason(path),
          active: matchesPath(path, 2),
        }
      }),
      active: matchesPath(path, 1),
    }
  })

  const routes =
    level === 'nav'
      ? resolved
      : resolved.find((r) => matchesPath(r.path, 1))?.tabs

  debugLog('router', () => [{ level, resolved, matches, routes }])

  if (!routes) {
    throw new Error(`Nav routes could not be derived for ${level}`)
  }

  return routes

  function withSeason(path: string) {
    return season && season.fields.id
      ? path.replace(/:seasonId/, season.fields.id)
      : path
  }

  function matchesPath(path: string, index: number) {
    return index in matches ? matches[index].pathname === `${path}` : false
  }
}
