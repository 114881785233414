import { startTransition } from 'react'
import {
  LinkProps,
  Link as RouterLink,
  NavLink as RouterNavLink,
  useLocation,
  useNavigate,
} from 'react-router-dom'

/**
 * Wrapper for React Router's Link component that persists query string
 * parameters. Used to keep 'debug' parameter when in use.
 */
export function Link({
  to,
  onClick,
  ...props
}: LinkProps & React.RefAttributes<HTMLAnchorElement>) {
  const { search } = useLocation()
  const navigate = useNavigate()

  return (
    <RouterLink
      to={to + search}
      onClick={(e) => {
        e.preventDefault()

        startTransition(() => navigate(to + search))

        if (onClick) {
          onClick(e)
        }
      }}
      {...props}
    />
  )
}

/**
 * Wrapper for React Router's NavLink component that persists query string
 * parameters. Used to keep 'debug' parameter when in use.
 */
export function NavLink({
  to,
  onClick,
  ...props
}: LinkProps & React.RefAttributes<HTMLAnchorElement>) {
  const { search } = useLocation()
  const navigate = useNavigate()

  return (
    <RouterNavLink
      to={to + search}
      onClick={(e) => {
        e.preventDefault()

        startTransition(() => {
          navigate(to + search)

          if (onClick) {
            onClick(e)
          }
        })
      }}
      {...props}
    />
  )
}
