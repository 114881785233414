import { DataStore, Predicates, SortDirection } from 'aws-amplify'
import isString from 'lodash/isString'
import { setModel } from '.'
import { Parking } from '../models'
import { ParkingModel } from '../types/model'

export async function getParking(id: string) {
  const results = await DataStore.query(Parking, (v) => v.id.eq(id))
  return results[0]
}

export async function listParking() {
  return await DataStore.query(Parking, Predicates.ALL, {
    sort: (s) => s.name(SortDirection.ASCENDING),
  })
}

export function initParking(): ParkingModel {
  return {
    fields: {
      name: undefined,
      streetAddress: undefined,
      city: undefined,
      zipCode: undefined,
      notes: undefined,
    },
  }
}

export async function createParking(
  parkingOrId?: Parking | string | null
): Promise<ParkingModel> {
  const parking = isString(parkingOrId)
    ? await getParking(parkingOrId)
    : parkingOrId || null

  const model = initParking()

  return setModel(parking, model)
}

export async function saveParking(model: ParkingModel) {
  const { fields } = model

  const parking = fields.id
    ? Parking.copyOf(await getParking(fields.id), (e) =>
        Object.assign(e, fields)
      )
    : new Parking(fields)

  await DataStore.save(parking)
}

export async function deleteParking(model: ParkingModel) {
  const { fields } = model

  if (fields.id) {
    await DataStore.delete(await getParking(fields.id))
  }
}
